<template >
  <div id="app" class="sticky-footer sticky-footer--highlight sticky-footer--backdrop">
		<div class="flex p-4 top-menu mb-4">
			<GMLogo class="ml-4 text-green-700 h-7" name="horizontal" />
		</div>
		<div class="flex flex-1">
			<slot />
		</div>
	</div>
</template>


<script lang="ts">
import {GMLogo} from '@gm/components';

export default defineComponent({
	components: {
		GMLogo,
	},
})
</script>
